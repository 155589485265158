import * as React from "react"
import "./spinner.css"

const Spinner = () => {
  return (
    <span className="spinner">
      🥺
    </span>
  )
}

export default Spinner
