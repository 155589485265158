import React, { useRef, useState, useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SignUpCard from "../components/signup-card"
import SEO from "../components/seo"

import "./index.css"

const IndexPage = () => {
  const pickRandomAdvice = () => {
    const advice = ["Try your best to not die!", "Hey you, yeah you, it's going to be okay.", "Go on a walk. NOW.", "Don't pay your taxes. How will the government punish you if they don't have tax dollars?", "Thou shalt not steal users' data.", "Follow your instincts. Unless they're wrong.", "Follow the path that leads you the furthest. Make sure to leave some breadcrumbs.", "If your hair is thinning, try dying your hair a similar tone to your scalp.", "If it doesn't work the first time, try again.", "Don't go chasing waterfalls.", "Tardigrades are some of the most resilient life forms scientists have ever discovered.", "When your heart rate goes up, breathe. It should go down after that.", "Feeling stressed? Sniff some black pepper.", "Remove mental overhead and keep notes of what you're working on.", "Leave a few minutes early for work in the morning to reduce the chances of elevated stress while rushing in traffic.", "Know that you are loved. I hope."]
    const randomIndex = Math.floor(advice.length * Math.random())
    setCurrentAdvice(advice[randomIndex])
  }

  const intervalId = useRef(null)
  const [currentAdvice, setCurrentAdvice] = useState('')

  useEffect(() => {
    pickRandomAdvice()
    intervalId.current = setInterval(() => pickRandomAdvice(), 2000)

    return () => clearInterval(intervalId.current)
  }, [])

  return (
    <Layout>
      <SEO />
      <h1 className="main-title">Emotional Support Bot</h1>
      <q className="quote">{currentAdvice}</q>

      <SignUpCard />
    </Layout>
  )
}

export default IndexPage
