import React, { useState } from "react"
import axios from 'axios'
import './signup.css'
import Spinner from "./spinner"

const SignUpCard = () => {
  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)


  const submitSubscribe = async () => {
    const config = {
      url: "https://us-central1-emotional-support-bot-309800.cloudfunctions.net/addNewMember",
      method: 'post',
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        name,
        email
      }
    }

    await axios(config)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    await submitSubscribe()
    setDone(true)
  }

  if (done) {
    return (
      <div>
        <h2 className="big-emoji">🥰</h2>
        <p className="feedback-text">Check your inbox!</p>
      </div>
    )
  }

  return (
    <form className="subscribe-card" onSubmit={handleSubmit}>
      <h2>Join Now</h2>
      <p>Fix all of life's problems with a daily piece of advice. Automatically generated by a bot, as all things should be.</p>
      <input name="name" type="text" required minLength={2} onChange={(e) => setName(e.target.value)} placeholder="Name" />
      <input name="email" type="email" required onChange={(e) => setEmail(e.target.value)} placeholder="Email" />

      <button className="subscribe-button" type="submit" disabled={loading}>{loading ? <Spinner /> : <>make me happy again &#10230;</>}</button>
    </form>
  )
}

export default SignUpCard
